import { css } from '@emotion/react'
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { CataloguesService } from '../../../services/LandingV4/catalogues'

import  BeneficiariosCard  from './BeneficiariosCard'
import {Typography, Box} from "@material-ui/core"

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class ManagerCard extends Component {

    constructor(){
        super()
        this.state = {
            beneficiariosExtra: [],
            count: 1,
            porcentajeUnit: {'Beneficiario-1': 100, 'Beneficiario-2': 100},
            error: {},
            generalError: true,
            beneficiarios:[],
            catalogoRelations:[],
        }
    }

    componentDidMount(){
        if(sessionStorage){
            const flujo =  JSON.parse(sessionStorage.getItem('flujo'))
            const catalogos = new CataloguesService()
            catalogos.getCataloguesRelationship(flujo.creditApplicationId)
            .then(({data}) => { 
                this.setState({catalogoRelations:data})
            })
            .catch(ex => console.error(ex));
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevState.generalError !== this.state.generalError){
            this.props.onError({value: this.state.generalError})
        }

        if(prevState.error !== this.state.error){
            this.watchError()
        }
        if(prevState.beneficiarios !== this.state.beneficiarios){
            let dataArr = []
            for(let nowItem in this.state.beneficiarios){
              dataArr = [...dataArr, this.state.beneficiarios[nowItem]]
            }
            this.props.onChange(dataArr)
        }
        if(prevState.beneficiariosExtra !== this.state.beneficiariosExtra){
            let unit = this.state.porcentajeUnit
            for(let nowItem in this.state.porcentajeUnit){
                unit = {...unit, [nowItem]: 100 / this.state.count}
                this.setState({
                    porcentajeUnit: unit
                })
            }
        }
    }

    watchError = () => {
        let newArr = []
        for(var nowItem in this.state.error){
            newArr = [...newArr, this.state.error[nowItem]]
        }
        this.setState({generalError: newArr.includes(true) || this.count === newArr.length })
    }

    addBeneficiario =  () => {
        if(this.state.count < 2){
            this.setState({count: this.state.count + 1}, () => {
                this.setState({
                    beneficiariosExtra:[...this.state.beneficiariosExtra, `Beneficiario-${this.state.count}`]
                })
            })
        }
    }

    handleCloseExtra = (e) => {
        const itemDell = e.id
        let newObjError = {}
        let newObjReferences = {}
        this.setState({count: this.state.count - 1})
        for(let nowItem in this.state.error){
            if(nowItem !== itemDell){
                newObjError = {...newObjError, [nowItem]:this.state.error[nowItem]}
            }
        }

        for(let nowItem in this.state.beneficiarios){
            if(nowItem !== itemDell){
                newObjReferences = {...newObjReferences, [nowItem]:this.state.beneficiarios[nowItem]}
            }
        }
        const filter = this.state.beneficiariosExtra.filter(item => item !== itemDell && item)
        this.setState({
            beneficiariosExtra: filter,
            error: newObjError,
            beneficiarios: newObjReferences,
        })
    }

    handleError = (e) => {
        this.setState({error:{...this.state.error, [e.id]:e.value}})
    }

    handleSave = (e) => {
        this.setState({beneficiarios:{...this.state.beneficiarios, [e.id]: e.value } })
    }

    handlePorcentage = (e) => {
        if(this.state.count > 1){
            let unit = this.state.porcentajeUnit
            for(var nowItem in this.state.porcentajeUnit){
                if(nowItem !== e.id){
                   unit = {...unit, [nowItem]: 100 - e.value }
                   this.setState({
                    porcentajeUnit: unit
                    })
                }
            }
        }
    }

    render() {

    let btnAddBenef = css`
    margin-top: 1rem;
    padding: 1rem;
    border: solid 1px rgba(0,0,0,0.3);
    border-radius: 25px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    ${this.state.generalError ? 'pointer-events:none;': ''}
    & .btnText{
        font-size: 20px;
        line-height: 1.6;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.23);
    }
    & .btnIcon{
        color:rgba(0, 0, 0, 0.54);
    }
`    
        const { beneficiariosExtra, porcentajeUnit } = this.state
        return (
            <Fragment>
                    <BeneficiariosCard
                        onChange={e => this.handleSave(e)} 
                        onError={this.handleError} 
                        defaultPorcentaje={porcentajeUnit["Beneficiario-1"]} 
                        catalogues={this.state.catalogoRelations} 
                        changePorcentage={this.handlePorcentage}
                        beneficiarioExtra={beneficiariosExtra.length < 1 ? true : false}
                    />
                    <br/>
                    {
                        beneficiariosExtra.length !== 0 ?
                        beneficiariosExtra.map((item) => {
                            return <BeneficiariosCard 
                                    key={item} 
                                    id={item} 
                                    onClose={this.handleCloseExtra} 
                                    onChange={e => this.handleSave(e)} 
                                    onError={this.handleError} 
                                    defaultPorcentaje={porcentajeUnit[item]} 
                                    catalogues={this.state.catalogoRelations} 
                                    changePorcentage={this.handlePorcentage}
                                />
                        })
                        : null
                    }
                    {this.state.count < 2 &&
                    <Box 
                        id="seguro-vida-nuevoBeneficiario-button"
                        onClick={this.addBeneficiario}
                        display="flex"
                        justifyContent="space-around"
                        alignItems="center"
                        css={btnAddBenef}
                    >
                        <PersonAddIcon className="btnIcon" />
                        <Typography className="btnText">Agregar otro beneficiario</Typography>
                        <ExpandMoreIcon className="btnText"/>
                    </Box>
                    }
            </Fragment>
        );
    }
}


ManagerCard.propTypes = {
    onError: PropTypes.func,
    onChange: PropTypes.func,
};


export default ManagerCard;
